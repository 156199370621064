<template>
    <div v-if="isShow">
        <div class="wrapper">
            <div class="left"><router-link :to="`category/${article.category.id}`">{{article.category.name}}</router-link></div>
            <div class="middle">
                <div class="main">
                    <div class="title"><h2>{{article.title}}</h2></div>
                    <div class="info">
                        <div class="info_time"><div class="el-icon-time"></div> {{article.update_time.slice(0,10)}}</div>
                    </div>
                    <div class="content" v-html="article.content"></div>
                    <hr>
                    <div class="info_tag">
                        <div class="info_tag_item">
                            <router-link :to="`/tag/${value.id}`"
                                         v-for="value in article.tags"
                                         :key="value.id"
                                         :class="value.color">
                                {{value.tag_name}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="right">
                <div class="tags">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getBlogById} from '../../api/blog'

    export default {
        name: "Category",
        data() {
            return {
                article: [],
                isShow: false,
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                getBlogById(this.$route.params.id).then(res => {
                    this.article = res.data
                    this.isShow = true
                })
            },

        }
    }
</script>

<style lang="less" scoped>
    .wrapper {
        margin: 3em 5em 3% 5em;
        display: flex;
        background-color: whitesmoke;

        .left {
            position: relative;
            flex: 1;
            display: inline-block;
            vertical-align: top;

            a {
                position: absolute;
                text-align: center;
				text-decoration: none;
                transform: rotate(-45deg);
                margin-top: 20%;
				margin-left: 5%;
                color: white;
				transition: 500ms all;

				&:hover{
					font-size: 1.1em;
				}
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                border: 4em solid transparent;
                border-left: 4em solid #ff4943;
                border-top: 4em solid #ff4943;
            }
        }

        .right {
            position: relative;
            flex: 1;
            display: inline-block;
            vertical-align: top;

            .tags {
                position: absolute;
                text-align: center;
                transform: rotate(45deg);
                margin-top: 25%;
                margin-left: 45%;
                color: white;

                a {
                    text-decoration: none;
                    color: white;
                    transition: 500ms all;
                    padding-left: .2em;

                    &:hover {
                        color: skyblue;
                    }
                }
            }

            &:before {
                content: "";
                position: absolute;
                right: 0;
                border: 4em solid transparent;
                border-right: 4em solid #ff4943;
                border-top: 4em solid #ff4943;
            }

        }

        .middle {
            flex: 8;
            display: inline-block;
            vertical-align: top;

            .main {
                position: relative;
                text-align: center;
                margin-top: 4em;
                height: 80%;

				hr{
					margin-top: 2em;
				}

                .info_tag {
                    width: 94%;
                    position: relative;
                    bottom: 1em;
                    height: 32px;
					margin-top: 2em;

                    .info_tag_item {
						text-align: left;
						margin-left: 2em;
                        margin-top: .5em;

                        a {
                            text-decoration: none;
                            color: white;
                            margin-left: 2em;
                            position: relative;
                            padding: 0.5em 3em;
                            font-size: .8em;

                            &:before {
                                position: absolute;
                                content: '';
                                transform: translateY(-50%) translateX(50%) rotate(-45deg);
                                top: 50%;
                                right: 100%;
                                background-color: inherit;
                                width: 1.5em;
                                height: 1.5em;

                            }

                            &:after {
                                position: absolute;
                                content: '';
                                top: 50%;
                                left: -0.2em;
                                margin-top: -0.2em;
                                background-color: #fff;
                                width: .4em;
                                height: .4em;
                                border-radius: 5em;
                            }
                        }

                    }
                }

                .title {
                    font-size: 30px;
                }

				.info {
					margin-top: 1em;
				}

                .content {
					text-align: left;
                    margin-top: 4em;
					white-space: pre-line;
                    text-indent: 2em;
                }

            }
        }
    }

</style>